import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';
import { DollarSign, CheckCircle2, ArrowRight } from "lucide-react";
import Headers from './Component/Header';
import Footer from './Component/Footer';
import Request from './Component/requestEstimate';
import s11 from './Component/s11.png';
import pricing1 from './Component/pricing1.png';
import pricing2 from './Component/pricing2.png';
import pricing3 from './Component/pricing3.jpg';
import pricing4 from './Component/pricing4.jpg';
import pricing5 from './Component/pricing5.jpg';
import pricing6 from './Component/pricing6.jpg';
import pricing7 from './Component/pricing7.jpg';

function Contact() {
    const navigate = useNavigate();
    return (
        <div>
            <div><Headers /> </div>
            <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-end justify-content-center">
                            <div className="col-md-9 ftco-animate mb-2 text-center p-5 text-white ">
                                <h2 className=" "><div className='text-white  capitalize'>Pricing</div></h2>

                                <a href='/free-estimate'> <div className='btn btn-warning  '>Request an Estimate</div> </a>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            {/* <section class="bg-white sm:h-[80vh] sm:max-h-[500px] sm:mt-[50px]">
                <div className='container '>
                    <div class="row flex  ">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
                            <img src={pricing1} alt="Los Angeles" class=" h-fit max-h-[500px]" style={{ borderRadius: '6%' }} />
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
                            paddingInline: '5% 10%'
                        }}>
                            <div className='p-2 text-slate-800 text-left ' >
                                <p class=" font-bold  text-2xl">What should i budget?</p>
                                <p class=" font-medium break-words w-[380px] text-sm">Wondering about the cost of your next home upgrade? Our comprehensive pricing guide breaks down the costs for carpet, luxury vinyl, showers, and backsplashes. Explore competitive and transparent pricing to make informed decisions and elevate your space affordably.</p>
                                <div className='row'>
                                    <div class="col-md-12">
                                        <div class="card-box-c foo">
                                            <div class="card-body-c align-self-center">
                                                <p class="content-c py-3"><ul>
                                                    <li className='pt-2'>Shower</li>
                                                    <li className='pt-2'>Luxury Vinyl</li>
                                                    <li className='pt-2'>Carpet</li>
                                                    <li className='pt-2'>Backsplash</li>
                                                </ul></p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section> */}
            <section className="py-3">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-2 gap-12 items-center">
                        <div className="relative">
                            <img
                                src={pricing1}
                                alt="Modern interior"
                                className="rounded-2xl shadow-xl"
                            />
                            <div className="absolute -bottom-6 -right-6 bg-primary/10 w-64 h-64 rounded-full -z-10" />
                        </div>
                        <div className="space-y-6">
                            <h2 className="text-3xl font-bold tracking-tight">
                                What should I budget?
                            </h2>
                            <p className="text-muted-foreground">
                                Wondering about the cost of your next home upgrade? Our comprehensive pricing guide breaks down the costs for carpet, luxury vinyl, showers, and backsplashes. Explore competitive and transparent pricing to make informed decisions.
                            </p>
                            <div className="grid grid-cols-2 gap-4">
                                {['Shower', 'Luxury Vinyl', 'Carpet', 'Backsplash'].map((item) => (
                                    <div key={item} className="flex items-center space-x-2">
                                        <CheckCircle2 className="h-5 w-5 text-primary" />
                                        <span>{item}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="bg-white">
                <div className='container '>
                    <div class="row flex  ">

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 flex items-center justify-center p-2" style={{
                            paddingInline: '5% 10%'
                        }}>
                            <div className='p-2 text-slate-800 text-center ' >
                                <p class=" font-bold  text-2xl">Deluxe Floor to Ceiling Shower</p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
                            <img src={pricing2} alt="Los Angeles" class=" h-fit max-h-[400px]" style={{ borderRadius: '6%' }} />
                        </div>
                    </div>

                </div>

            </section>
            <section class="bg-white sm:h-[80vh] sm:max-h-[500px] sm:mt-[50px]">
                <div className='container '>
                    <div class="row flex  ">

                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
                            <img src={pricing3} alt="Los Angeles" class=" h-fit max-h-[400px]" style={{ borderRadius: '6%' }} />
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 p-2 ">
                            <img src={pricing4} alt="Los Angeles" class=" h-fit max-h-[400px]" style={{ borderRadius: '6%' }} />
                        </div>
                    </div>

                </div>

            </section>
            <section className="hero-wrap hero-wrap-2 my-3" style={{ backgroundImage: `url(${s11})` }} data-stellar-background-ratio="0.5">
                <div className="overlay" style={{ background: 'rgba(0, 0, 0, 0.7)' }}>
                    <div className="container">
                        <div class="grid md:grid-cols-3 grid-cols-1 gap-4">

                            <div class="p-2 flex items-center justify-center">
                                <img src={pricing6} alt="Los Angeles" class=" h-fit max-h-[650px]" style={{ borderRadius: '6%' }} />
                            </div>
                            <div class="p-2 ">
                                <img src={pricing7} alt="Los Angeles" class=" h-fit max-h-[650px]" style={{ borderRadius: '6%' }} />
                            </div>
                            <div class="p-2 ">
                                <img src={pricing5} alt="Los Angeles" class=" h-fit max-h-[650px]" style={{ borderRadius: '6%' }} />
                            </div>
                        </div>


                    </div>
                </div>

            </section>
            <Request />



            <div><Footer /> </div>
        </div>
    )
}

export default Contact
