import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logo from './home/logo.png';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JS
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
	const navigate = useNavigate();

	const onToggleMenu = () => {
		setMenuOpen(true);
	};
	const onCloseMenu = () => {
		setMenuOpen(false); // Close the menu
	};
 
	return (
		<div className='font-sans'>
			
			<div class="bg-black text-white ">
				<div className=' text-center pt-1.5 text-white  text-md font-bold ' style={{ backgroundColor: 'rgb(243, 127, 19)' }}><span>
					<p className='flex flex-row md:font-[10px] text-center items-center md:justify-center justify-between  px-3 font-bold'>
						<span
							className='font-bold cursor-pointer flex flex-row'
							onClick={() => {
								window.open('https://www.google.com/maps/place/482+State+Farm+Rd,+Boone,+NC+28607,+USA/@36.2063478,-81.6661099,17z/data=!3m1!4b1!4m6!3m5!1s0x8850fa46b39fc9b3:0x23af7b93dcfe7b12!8m2!3d36.2063435!4d-81.663535!16s%2Fg%2F11b8v66c4m?entry=ttu&g_ep=EgoyMDI0MDkwOC4wIKXMDSoASAFQAw%3D%3D', '_blank');
							}}
						>
							<i className="bi bi-geo-alt text-white font-bold"> </i><span className="hidden md:flex text-white font-bold">482 State Farm Rd Boone NC 28607</span>
						</span>
						<a href="tel:+18288651700" className='text-white font-semibold ml-3 flex flex-row'>
							<i className="bi bi-telephone pl-5 text-white font-bold">
							</i>
							<span className="flex text-white font-bold">(828) 865-1700</span>
						</a>
					</p>
				</span>
				</div>
			<div className="flex item-center  md:hidden justify-center pb-3" >
							<a href="/" class="d-block " ><img src={logo}  alt="Los Angeles" className='h-[150px]' /> </a>


						</div>
					<div class="flex items-center lg:order-2 pb-2 px-3">
					
						<button onClick={onToggleMenu}
							name={menuOpen ? 'close' : 'menu'}
							className="text-3xl cursor-pointer md:hidden">
							<span class="sr-only">Open main menu</span>
							<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
							<svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

						</button>

					</div>
				<div class="container ">


					<div class="flex items-center justify-center  pb-2">

						<div class="hidden md:flex md:items-center">					</div>

						<div className=" hidden md:flex md:item-center justify-center " >
							<a href="/" class="d-block "  ><img src={logo} alt="Los Angeles" className='h-[150px]'  /> </a>
						</div>
						<div
						className={`nav-links duration-500 md:static   text-white  pt-2  left-0 ${menuOpen ? 'top-[9%] bg-black relative' : 'top-[-100%] items-center justify-content-center absolute'
							} md:w-auto w-full flex  `}
					>
						<ul className="flex md:flex-row flex-col md:items-center  md:justify-content-center p-2  border-slate-700 font-sans">

							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<button class="text-white   font-semibold hover:text-purple-600 " onClick={onCloseMenu}>
									<i class="bi bi-x-square-fill"></i>
								</button>
							</li>
							{/* <li>
								<a href="/specials" class="text-white   btn px-3">Specials</a>
							</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> PRODUCTS</span>
									</button>
									<ul className="dropdown-menu bg-black !text-white">
										<li>
											<a href="/products" class=" dropdown-item !text-white  hover:!text-slate-900 hover:!bg-yellow-500 ">All Products</a>
										</li>
										<li>
											<a href="/products/carpet" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Carpet</a>
										</li>
										<li>
											<a href="/products/hardwood" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Hardwood</a>
										</li>
										<li>
											<a href="/products/laminate" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Laminate</a>
										</li>
										<li>
											<a href="/products/carpet-tile" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Carpet-tile</a>
										</li>
										<li>
											<a href="/products/waterproof_Flooring" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Waterproof Flooring</a>
										</li>
										<li>
											<a href="/products/luxuryvinyl" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">luxury Vinyl</a>
										</li>
										<li>
											<a href="/products/vinyl" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Vinyl</a>
										</li>
										<li>
											<a href="/products/cork" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Cork</a>
										</li>
										<li>
											<a href="/products/Solid-Surface" class=" dropdown-item !text-white hover:text-slate-900 hover:!bg-yellow-500 ">Solid-Surface</a>
										</li>


									</ul>
								</div>
							</li>
							{/* <li>
											<a href="/flooring-services" class=" text-white  btn px-3"> <span className='font-bold'> SERVICES</span></a>
										</li> */}
							<li className="nav-item">
								<div className="btn-group dropend">
									<button
										type="button"
										className="btn  dropdown-toggle font-semibold text-white"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<span className='font-bold'> SERVICES</span>
									</button>
									<ul className=" dropdown-menu bg-black !text-white">
										<li>
											<a href="/flooring-services" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">All Services</a>
										</li>
										<li>
											<a href="/hardwood-refinishing" class=" dropdown-item !text-white hover:!text-slate-900 hover:!bg-yellow-500 ">Hardwood refinishing</a>
										</li>
										{/* <li>
											<a href="/blinds-n-shutters" class=" dropdown-item hover:text-green-900 ">Blinds & Shutters</a>
										</li>
										<li>
											<a href="/builder-n-contractor-program" class=" dropdown-item hover:text-green-900 ">Builder & Contractor Program</a>
										</li>
										<li>
											<a href="/design-services" class=" dropdown-item hover:text-green-900 ">Design Services</a>
										</li> */}
									</ul>
								</div>
							</li>
							<li>
								<a href="/pricing" class="text-white btn px-3 font-bold"><span className='font-bold'> PRICING</span></a>
							</li>
							<li>
								<a href="/financing" class="text-white  btn px-3"><span className='font-bold'> FINANCING</span></a>
							</li>
							<li>
								<a href="/inspiration" class="text-white  btn px-3"><span className='font-bold'> INSPIRATION</span></a>
							</li>
							<li>
								<a href="/about-us" class="text-white  btn px-3"><span className='font-bold'> ABOUT</span></a>
							</li>
							<li>
								<a href="/contact" class="text-white  btn px-3"><span className='font-bold '> CONTACT</span></a>
							</li>
							<li className={`md:hidden ${menuOpen ? 'md:hidden' : 'md:hidden'}`}>
								<a href="/free-estimate" class=" text-white  btn px-3"><span className='font-bold '>REQUEST AN ESTIMATE</span></a>

							</li>


						</ul>
					</div>


						<div class="hidden  md:flex md:items-center px-3">
							<div><a href="/free-estimate" class=" text-white  btn btn-warning  px-3 py-2">REQUEST </a></div>
						</div>


					</div>
					



					
				</div>




			</div>


		</div>
	)
}

export default Header
